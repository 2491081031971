import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c5697222"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "content w-full bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GcHeader = _resolveComponent("GcHeader")!
  const _component_TemplateReportMultifacet = _resolveComponent("TemplateReportMultifacet")!
  const _component_TemplateReport = _resolveComponent("TemplateReport")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_GcHeader, { "profile-data": _ctx.profileData }, null, 8, ["profile-data"])
      ])
    ]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (
        _ctx.detailTestResult.user_results &&
        _ctx.detailTestResult.user_results.length > 0
      )
            ? (_openBlock(), _createBlock(_component_TemplateReportMultifacet, {
                key: 0,
                "is-loading": _ctx.isLoading,
                "detail-test-result": _ctx.detailTestResult
              }, null, 8, ["is-loading", "detail-test-result"]))
            : (_openBlock(), _createBlock(_component_TemplateReport, {
                key: 1,
                "is-loading": _ctx.isLoading,
                "detail-test-result": _ctx.detailTestResult
              }, null, 8, ["is-loading", "detail-test-result"]))
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}